import React from "react";

import { Skeleton } from "antd";

import TProgressInfo from "../../../ProgressInfo";
import {
  getResultByScore,
  isTaskLoading,
  RESULT_SCORE,
} from "../../../../utilities/Function";
import "./style.scss";

interface MyReportProps {
  id: number;
  className?: string;
  name: string;
  content_type: string;
  percentage?: number;
  status?: number;
  message?: string;
  description?: string;
  review?: number;
}

const TResultItem = (props: MyReportProps) => {
  const response = getResultByScore(props.percentage);
  console.log("🚀 ~ TResultItem ~ response:", response);

  return (
    <div
      className={`ResultItem  ${props.className} ${
        isTaskLoading(props.status) ? " Result__loading" : ""
      } `}
    >
      <div className="text-black">
        <p style={{ fontSize: "1rem" }}>Resultado del motor</p>
        <p
          style={{ fontSize: "1.2rem", color: "#2B3447" }}
          className="font-medium-2"
        >
          {props.name}{" "}
          <a
            className="ml-0"
            style={{
              color: "#4343FF",
              fontSize: ".9rem",
              textDecoration: "underline",
            }}
          >
            Mostrar evidencias
          </a>
        </p>
        <p style={{ color: "#2B3447", width: "90%" }}>{props?.description}</p>
      </div>
      {isTaskLoading(props.status) ? (
        <Skeleton avatar paragraph={{ rows: 1 }} active />
      ) : (
        <>
          <TProgressInfo
            size={70}
            strokeWidth={5}
            color={response?.color}
            className={"ResultItem__progress"}
            percentage={props.percentage}
            status={props.status}
            message={props.message}
            content={response?.name}
          />
        </>
      )}
    </div>
  );
};

TResultItem.displayName = "TResultItem";

TResultItem.defaultProps = {
  className: "",
};

export default TResultItem;
