import React, { useState } from "react";

import "./style.scss";
import { Button, Checkbox, Dropdown, Space } from "antd";
import { CONTENT_DATA_FILTER } from "./Constants";
import type { MenuProps } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { DownOutlined } from "@ant-design/icons";

// Define your props here
interface FilterSelectProps {
  className?: string;
  onFilter?: (value: string[]) => void;
}

const FilterSelect = (props: FilterSelectProps) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const handleFilterChange = (state) => {
    setSelectedKeys(state.selectedKeys);
    if (props.onFilter) {
      props.onFilter(state.selectedKeys);
    }
  };

  const items: ItemType[] = CONTENT_DATA_FILTER.map((option) => {
    return {
      label: (
        <>
          {option.label}{" "}
          <Checkbox checked={selectedKeys.includes(option.key)} />{" "}
        </>
      ),
      key: option.key,
    };
  });

  const menu: MenuProps = {
    multiple: true,
    selectable: true,
    items: items,
    onSelect: handleFilterChange,
    onDeselect: handleFilterChange,
  };

  return (
    <div className={`FilterSelect ${props.className}`}>
      <Dropdown
        trigger={["click"]}
        overlayStyle={{ minWidth: "156.548px" }}
        menu={menu}
      >
        <Button type="primary">
          <Space>
            Filtros
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

FilterSelect.displayName = "Filter";

FilterSelect.defaultProps = {
  className: "",
};

export default FilterSelect;
