import React, { useState } from "react";

import { Checkbox, Modal, Progress } from "antd";

import "./style.scss";
import { getResultsV2 } from "../../../Result/Constants";

// Define your props here

interface MyReportPrintProps {
  className?: string;
  open: boolean;
  setIsModalOpen: (value: boolean) => void;
  motors?: [];
  onDownloadReport: (value: string[]) => void;
}

interface MotorInterface {
  algorithm: string;
  ai_score: number;
  algorithm_description: string;
}

const TMyReportPrint = (props: MyReportPrintProps) => {
  const [motorsSelected, setMotorsSelected] = useState([]);
  const handleOk = () => {
    props.setIsModalOpen(false);
    props.onDownloadReport(motorsSelected);
  };

  const handleCancel = () => {
    props.setIsModalOpen(false);
  };

  const onHandleMotor = (evt, motor) => {
    if (evt.target.checked) {
      setMotorsSelected([...motorsSelected, motor]);
    } else {
      setMotorsSelected(motorsSelected.filter((item) => item !== motor));
    }
  };

  return (
    <div className={`TMyReportPrint ${props.className}`}>
      <Modal
        className="TMyReportPrint__modal"
        title="Descargar PDF"
        open={props.open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Descargar"
        cancelText="Cancelar"
      >
        <div className="TMyReportPrint__header mt-1">
          <p>Selecciona los análisis que quieres incluir en el documento:</p>
        </div>

        <div className="TMyReportPrint__body mt-1 mb-1">
          {props.motors.length > 0 &&
            props.motors.map((motor: MotorInterface) => {
              const { color } = getResultsV2(motor.ai_score);

              return (
                <div className="mb-1 TMyReportPrint__body-item full-center ">
                  <Checkbox
                    onChange={(evt) => onHandleMotor(evt, motor?.algorithm)}
                  >
                    <p className="font-regular">{motor?.algorithm_description}</p>
                  </Checkbox>
                  <p>Probabilidad de IA: </p>
                  <Progress
                    type="circle"
                    percent={Math.floor(motor.ai_score)}
                    strokeColor={color}
                    size={36}
                    strokeWidth={5}
                  />
                </div>
              );
            })}
        </div>
      </Modal>
    </div>
  );
};

TMyReportPrint.displayName = "TMyReportPrint";

TMyReportPrint.defaultProps = {
  className: "",
};

export default TMyReportPrint;
