/* eslint-disable import/no-anonymous-default-export */
import ErrorPage from "../../pages/ErrorPage";
import {
  HomePage,
  HowItWorksPage,
  HelpPage,
  LegalPage,
  ConditionPage,
  PrivacyPage,
} from "../../pages/index";
import LoginPage from "../../pages/login/LoginPage";
import ReportPage from "../../pages/report/ReportPage";
import ReportDetailPage from "../../pages/reportDetail/ReportDetailPage";
import { ROUTES_GENERAL } from "../../utilities/Constant";

export default [
  {
    path: ROUTES_GENERAL.HOME_PAGE,
    component: HomePage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.HOW_WORKS,
    component: HowItWorksPage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.HELP,
    component: HelpPage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.LEGAL,
    component: LegalPage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.CONDITIONS,
    component: ConditionPage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.PRIVACY,
    component: PrivacyPage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.REPORT,
    component: ReportPage,
    exact: true,
  },
  {
    path: ROUTES_GENERAL.REPORT + "/:id",
    component: ReportDetailPage,
  },

  {
    path: ROUTES_GENERAL.LOGIN,
    component: LoginPage,
  },
  {
    path: "*",
    component: ErrorPage,
  },
];
