/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Input, Tabs, Typography } from "antd";

import { DEVICES, DEVICES_TAB } from "./Data";
import { useGetModels } from "../../../hooks/useGetModels";
import TDropZoneItems from "./Components/TDropZoneItems";
import TMotors from "./Components/TMotors";
import TUploadMultipleFile from "../../UploadMultipleFile";

import "../style.scss";
import { useDispatch } from "react-redux";
import { setLoadingPage } from "../../../configuration/reduxStore/Page/actions";

import { API_STATUS } from "../../../utilities/Constant";
import { file_uploaded } from "../../../hooks/usePageVisit";
import { EVENTS_TGM } from "../../../utilities/Events";
interface DropzoneProps {
  className?: string;
  loading?: boolean;
  uploadFile?: (params) => Promise<any>;
}

const STATUS_PROCCES = {
  0: "Inicial",
  1: "Procesando",
  2: "Terminado",
};

const initialState = {
  tags: "",
  algorithm_list: [],
  deviceSelected: DEVICES.image,
  files: [],
};

const TDropZoneV2 = (props: DropzoneProps) => {
  const [dataModels, loadingModels] = useGetModels();
  const [data, setData] = useState(initialState);
  const [status, setStatus] = useState(STATUS_PROCCES[0]);

  const dispatch = useDispatch();

  const onProcess = async () => {
    //TODO: improve this code
    if (data.algorithm_list.length < 1) {
      alert("Debe seleccionar un motor como mínimo");
      return;
    }
    if (data.tags === "") {
      data.tags = " ";
    }

    dispatch(setLoadingPage(true));
    const lastElement =
      data.files.length > 0 && data.files[data.files.length - 1];

    if (lastElement) {
      const updatedFiles = data.files.filter(
        (file) => file.uid !== lastElement.uid
      );

      onSendEvent(lastElement.name);
      const response = await props.uploadFile({
        ...data,
        file: lastElement.originFileObj,
        isLast: data.files.length === 1,
      });

      if (response === API_STATUS.success) {
        setStatus(STATUS_PROCCES[1]);
        setData({ ...data, files: updatedFiles });
      }

      if (response === API_STATUS.created) {
        setStatus(STATUS_PROCCES[2]);
        setData(initialState);
        onCheckAllDefaultMotor();
      }
    }
  };

  const onSendEvent = (name) => {
    file_uploaded({ page_type: EVENTS_TGM.home, data, name });
  };

  const onHandleMotor = (checked: boolean, motorSelected) => {
    const arrMotors = [...data.algorithm_list];
    if (checked) {
      arrMotors.push(motorSelected.id);
      setData({ ...data, algorithm_list: arrMotors });
    } else {
      const newArrMotors = arrMotors.filter(
        (motor) => motor !== motorSelected.id
      );
      setData({ ...data, algorithm_list: newArrMotors });
    }
  };

  const onCheckAllChange = (e) => {
    const defaultMotors = dataModels[data.deviceSelected].map((d) => d.id);
    setData({ ...data, algorithm_list: defaultMotors });
  };

  const onCheckAllDefaultMotor = () => {
    const defaultMotors = dataModels[data.deviceSelected]
      .filter((d) => {
        const supportAudioandVideo =
          d.content_type === "audio,video" && data.deviceSelected === "video";
        if (!supportAudioandVideo) return d.id;
      })
      .map((d) => d.id);

    setData({ ...data, algorithm_list: defaultMotors, files: [] });
  };

  useEffect(() => {
    if (status === STATUS_PROCCES[1]) {
      onProcess();
    }
  }, [status, data.files]);

  useEffect(() => {
    if (dataModels[data.deviceSelected]) {
      onCheckAllDefaultMotor();
    }
  }, [dataModels, data.deviceSelected]);

  return (
    <div className={`dropzone v2 ${props.className}`}>
      <div className="custom-upload">
        <Tabs
          activeKey={data.deviceSelected}
          items={DEVICES_TAB}
          onChange={(key) => setData({ ...data, deviceSelected: key })}
        />

        <div className="dropzone__body">
          <div className="dropzone__actions f-1">
            <Typography.Title level={5} className="font-regular">
              Seleccione los motores para el análisis
            </Typography.Title>
            <TMotors
              onCheckAllChange={onCheckAllChange}
              loadingModels={loadingModels}
              onChange={onHandleMotor}
              device={data.deviceSelected}
              dataModels={dataModels}
              values={data.algorithm_list}
            />
            {data?.deviceSelected && (
              <TDropZoneItems device={data.deviceSelected} />
            )}
          </div>
          <div className="dropzone__body-line" />
          <div className="dropzone__body-upload">
            <Typography.Title level={5} className="font-regular">
              Escriba una etiqueta ( opcional )
            </Typography.Title>
            <Input
              defaultValue={data.tags}
              onChange={(e) => setData({ ...data, tags: e.target.value })}
              placeholder={"Escribe una etiqueta para tu archivo"}
            />

            <Typography.Title level={5} className="font-regular mt-0">
              Sube tu archivo
            </Typography.Title>
            <TUploadMultipleFile
              fileList={data.files}
              onChange={(files) => setData({ ...data, files: files })}
              loading={props.loading}
              onSubmit={onProcess}
              deviceSelected={data.deviceSelected}
              className="mt-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TDropZoneV2.displayName = "TDropZoneV2";

TDropZoneV2.defaultProps = {
  className: "",
};

export default TDropZoneV2;
