import { useState } from "react";

const publicUrl = `${process.env.REACT_APP_BASE_URL}/api/pub/auth`;

export const useForgotPassword = () => {
  const [loadingPassword, setLoading] = useState(false);
  const forgotPassword = async (values) => {
    let myHeaders;
    myHeaders = {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    try {
      setLoading(true);
      const { newPassword, currentPassword } = values;
      const payload = new FormData();
      payload.append("old_password", currentPassword);
      payload.append("new_password", newPassword);

      let response = await fetch(publicUrl + "/change_password", {
        method: "POST",
        headers: myHeaders,
        body: payload,
      });
      return response.status;
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  return [loadingPassword, forgotPassword] as const;
};
