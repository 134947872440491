import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import "./style.scss";
import { ROUTES_GENERAL } from "../../utilities/Constant";
import { t } from "../../utilities/Message";
import { pathServer } from "../../utilities/Function";
import { Button, Tooltip } from "antd";

interface FooterProps {
  className?: string;
}

export const TELEFONICA_MAIL = "verifai@tu.com";

const TFooter = (props: FooterProps) => {
  const onMailTo = () => {
    window.location.href = "mailto:" + TELEFONICA_MAIL;
  };

  return (
    <footer className="footer max-padding">
      <div className="footer__header">
        <img
          src={pathServer.PATH_LOGO + "logo_pro.svg"}
          alt="veriFAI"
          width={150}
        />
        <p className="mt-1 mb-1 footer__info">
          {t("app.Landing.Footer.title2.description")}{" "}
        </p>
      </div>

      <div className="footer__content mt-1">
        <Link to={ROUTES_GENERAL.LEGAL}>
          {t("app.Landing.Footer.title1.legal")}
        </Link>

        <Link to={ROUTES_GENERAL.PRIVACY}>
          {t("app.Landing.Footer.title1.policy")}
        </Link>

        <div className="footer__mail">
          <Tooltip title={"email: " + TELEFONICA_MAIL}>
            <Button onClick={onMailTo}>
              {" "}
              {t("app.Landing.Footer.title3")}
            </Button>
          </Tooltip>
        </div>
      </div>
    </footer>
  );
};

TFooter.displayName = "TFooter";

TFooter.defaultProps = {
  className: "",
};

export default TFooter;
