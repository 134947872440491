import React from "react";
import { ReactSVG } from "react-svg";
import { pathServer } from "../../../utilities/Function";

export const DEVICES = {
  audio: "audio",
  video: "video",
  image: "image",
};

export const DEVICES_TAB = [
  {
    key: DEVICES.image,
    label: (
      <div className="d-flex">
        <p>Imagen</p>
        <ReactSVG
          src={pathServer.PATH_ICONS + "ic_device_image.svg"}
          className="item-icon icon-medium"
        />
      </div>
    ),
    items: ["Hasta 2400x2400 pixeles"],
  },
  {
    key: DEVICES.video,
    label: (
      <div className="d-flex">
        <p>Vídeo</p>
        <ReactSVG
          src={pathServer.PATH_ICONS + "ic_device_video.svg"}
          className="item-icon icon-medium"
        />
      </div>
    ),
    items: ["Video con 1 cara", "Duración 20-60 segundos"],
  },
  {
    key: DEVICES.audio,
    label: (
      <div className="d-flex">
        <p>Audio</p>
        <ReactSVG
          src={pathServer.PATH_ICONS + "ic_device_audio.svg"}
          className="item-icon icon-medium"
        />
      </div>
    ),
    items: ["Duración 10-60 segundo"],
  },
];
