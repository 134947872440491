import moment from "moment-timezone";
import "moment/locale/es";
import { formatDateApi, formatDatePicker } from "./Constant";
import {
  STATUS_TAKS,
  STATUS_TAKS_NUMBER,
} from "../components/MyReport/Constants";
// .tz('America/Lima', true).locale('es')
export default {
  trimCharacer: (str: string, character?: string): string => {
    str = str.replace(/\s/g, character ? character : "");
    let res: string = str;
    return res;
  },
  fixeToTwiDecimals: (value: number): string => {
    return (+value).toFixed(2);
  },
  formatToMoney: (value: number): string => {
    return `S/ ${(+value).toFixed(2)}`;
  },
};

export function hideText(text) {
  // Convertir el número a cadena
  var numberString = String(text);

  // Obtener las primeras 4 letras
  var firstDigits = numberString.substring(0, 4);

  // Obtener las últimas 3 letras
  var lastDigits = numberString.substring(numberString.length - 12);

  // Crear el número oculto
  var hiddenNumber = "xxxx" + lastDigits + "xxx";

  return hiddenNumber;
}
export function formatToMoney(value) {
  if (value === undefined) {
    return `S/0.00`;
  }

  if (isNaN(value)) {
    return `S/${+value}`;
  }
  return `S/ ${(+value).toFixed(2)}`;
}

export function maxLengthCheck(evt) {
  const theEvent = evt || window.event;
  let key;
  // Handle paste
  if (theEvent.type === "paste") {
    key = evt.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]|\./;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}

export const formatSecondsToMMSS = (seconds) => {
  const duration = moment.duration(seconds, "seconds");
  const formattedTime = moment
    .utc(duration.asMilliseconds())
    .format("mm:ss.SS");
  return formattedTime;
};

export function removeDays(date, days) {
  date = new Date(date);
  date.setDate(date.getDate() - days);
  return date;
}

const getSuccessTasks = (subTasks) =>
  subTasks.filter(
    (task) =>
      task?.status === STATUS_TAKS_NUMBER.finished && task?.ai_score >= 0
  );

const getTaskCancelled = (subTasks) =>
  subTasks.filter((task) => task?.status === STATUS_TAKS_NUMBER.failed);

const calculateScore = (subTasks) => {
  const amount = subTasks.reduce((acc, task) => acc + task.ai_score, 0);
  return amount / subTasks.length;
};

export const averageAIScore = (subtasks) => {
  const filterTasksSuccess = getSuccessTasks(subtasks);
  const filterTasksCancelled = getTaskCancelled(subtasks);

  if (filterTasksCancelled.length >= 0 && filterTasksSuccess.length === 0) {
    return -1;
  }

  if (filterTasksSuccess.length === 0) return null;

  return calculateScore(filterTasksSuccess);
};

export const RESULT_SCORE = {
  authentic: {
    id: 0,
    name: "Auténtico",
    background: "#AEDDD080",
    color: "#009B7A",
    icon: "ic_valid.svg",
    range: "0-33%",
  },
  suspicious: {
    id: 1,
    name: "Sospechoso",
    background: "#FAF7DA80",
    color: "#958946",
    icon: "ic_precaution.svg",
    range: "34-66%",
  },
  manipulated: {
    id: 2,
    name: "Manipulado",
    background: "#FAEDEBBF",
    color: "#E66C64",
    icon: "ic_bad.svg",
    range: "67-100%",
  },
  undetermined: {
    id: 3,
    name: "Indeterminado",
    background: "#F2F4FF80",
    color: "#D1D5E4",
    icon: "ic_none.svg",
    range: "Error",
  },
  default: {
    id: "-",
    name: null,
    background: "-",
    color: "-",
    icon: null,
    range: "-",
  },
};

export const RESULT_SCORE_MAP = new Map(Object.entries(RESULT_SCORE));
Object.freeze(RESULT_SCORE);

export const getResultByScore = (score) => {
  if (score < 0) return RESULT_SCORE.undetermined;
  if (score >= 0 && score <= 33) {
    return RESULT_SCORE.authentic;
  }
  if (score > 33 && score <= 66) {
    return RESULT_SCORE.suspicious;
  }

  if (score > 66 && score <= 100) {
    return RESULT_SCORE.manipulated;
  }
  if (!score) return RESULT_SCORE.undetermined;
};

export const isTaskLoading = (status) =>
  status === STATUS_TAKS_NUMBER.queue || status === STATUS_TAKS_NUMBER.running;

export function formatDate(date) {
  return moment(date).format(formatDateApi);
}

export function formatDatePrintRemmisse(dateFrom, dateTo) {
  const dateWeek = moment(dateFrom).format("dddd");
  const dateFormattedStart = moment(dateFrom).format(formatDatePicker);
  const dateFormattedEnd = moment(dateTo).format("DD MMM, YYYY");
  return dateWeek + " " + dateFormattedStart + " - " + dateFormattedEnd;
}

export const pathServer = {
  PATH_ICONS: `/icons/`,
  PATH_LOGO: `/logo/`,
  PATH_IMG: `/images/`,
  PATH_FAVICON: `/favicon/`,
};

export const onLoadThumbnail = (
  urlImage,
  thumbnail_width,
  thumbnail_height,
  setImages,
  lengthImages
) => {
  const loadedImages = [];

  const originalImage = new Image();
  originalImage.crossOrigin = "Anonymous";
  originalImage.src = urlImage;
  originalImage.onload = function () {
    for (let i = 0; i < lengthImages; i++) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const sectionWidth = thumbnail_width;
      const sectionHeight = thumbnail_height;

      ctx.clearRect(0, 0, sectionWidth, sectionHeight);
      ctx.drawImage(
        originalImage,
        i * sectionWidth,
        0,
        sectionWidth,
        sectionHeight,
        0,
        0,
        sectionWidth,
        sectionHeight
      );
      loadedImages.push(canvas.toDataURL());
    }
    setImages(loadedImages);
  };

  return loadedImages;
};
