import React, { useContext } from "react";
import { Row, Col } from "antd";

import { pathServer } from "../../utilities/Function";

import TDropZoneV2 from "../DropZone/v2";
import "./style.scss";
interface BannerProps {
  className?: string;
  uploadFile?: (params) => Promise<any>;
  uploadText?: (params: string) => void;
  loading: boolean;
}

const TBanner = (props: BannerProps) => {
  return (
    <div className={`banner ${props.className} max-padding`}>
      <Row gutter={[16, 16]} className="max-width">
        <Col className="w-100">
          <h1 className="banner__title">
            <img
              src={pathServer.PATH_LOGO + "logo_pro.svg"}
              alt="logo"
              width={250}
              className="img-full"
            />
          </h1>

          <TDropZoneV2 loading={props.loading} uploadFile={props.uploadFile} />
        </Col>
      </Row>
    </div>
  );
};

TBanner.displayName = "TBanner";

TBanner.defaultProps = {
  className: "",
};

export default TBanner;
