import React from "react";

import { Progress } from "antd";

import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import "./style.scss";

// Define your props here
interface ProgressInfoProps {
  className?: string;
  percentage?: number;
  color?: string;
  content?: string;
  result?: string;
  size?: number;
  strokeWidth?: number;
  status?: number;
  message?: string;
}

const TProgressInfo = (props: ProgressInfoProps) => {
  const {
    percentage,
    color,
    content,
    size = 160,
    strokeWidth = 3,
    status,
  } = props;

  return (
    <div className={`ProgressInfo ${props.className}`}>
      {status === 3 ? (
        <ReactSVG
          src={pathServer.PATH_ICONS + "ic_failed.svg"}
          className="icon-large"
        />
      ) : (
        <Progress
          type="circle"
          percent={Math.floor(percentage)}
          size={size}
          strokeColor={color}
          strokeWidth={strokeWidth}
          format={(text) => (
            <small style={{ color, fontSize: "1rem" }}> {text}%</small>
          )}
        />
      )}
      {status === 3 ? (
        <div>
          <p className="font-bold ml-1 mt-0">Contenido probablemente</p>
          <p className="ml-1 mt-0">Indeterminado</p>
        </div>
      ) : (
        <div className="m-auto ml-1">
          <p>
            Contenido probablemente
            <br />
            <strong className="uppercase font-bold">{content}</strong>{" "}
          </p>
        </div>
      )}
    </div>
  );
};

TProgressInfo.displayName = "TProgressInfo";

TProgressInfo.defaultProps = {
  className: "",
};

export default TProgressInfo;
