import React from "react";
import ReactDOM from "react-dom/client";
import App from "./presentation";
import { ReduxProvider } from "./presentation/configuration/reduxStore/Store";

import reportWebVitals from "./reportWebVitals";

import { LanguageProvider } from "./presentation/context/i18n/LanguageProvider";
import { VerifaiProvider } from "./presentation/context/verifai/VerifaiProvider";
import { ThemeProvider } from "./presentation/context/theme/ThemeProvider";
import { container } from "./inject_container/injection";
import { GTM_PORT, GTMPort } from "./domain/port/inbound/GTMPort";

import "./resources/sass/base.scss";

const gtm = container.get<GTMPort>(GTM_PORT);
gtm.init();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ReduxProvider>
    <ThemeProvider>
      <LanguageProvider>
        <VerifaiProvider>
          <App />
        </VerifaiProvider>
      </LanguageProvider>
    </ThemeProvider>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
