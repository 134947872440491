import React from "react";

import TMyReportTitleItem from "../MyReportTitleItem";
import "./style.scss";
import { STATUS_TAKS_NUMBER } from "../../../MyReport/Constants";
import { RESULT_SCORE_MAP } from "../../../../utilities/Function";

import { Button, Skeleton, Spin } from "antd";

import "./style.scss";
import { pathServer } from "../../../../utilities/Function";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import TSpinner from "../../../Spinner";

// Define your props here
interface MyReportProps {
  className?: string;
  status?: number;
  score?: {
    icon?: string;
    name?: string;
    range?: string;
    color?: string;
    background?: string;
  };
  rating?: number;
  onHandleReview?: (review: number) => void;
}

const STATUS_RATING_TASK = {
  initial: -1,
  like: 1,
  dislike: 0,
  unknown: 2,
};

const TRating = ({ review, onHandleReview }) => {
  const { loading } = useSelector((state: any) => state.page);

  const { like, dislike, initial, unknown } = STATUS_RATING_TASK;
  const onHandleRating = (e, rating) => {
    e.stopPropagation();
    onHandleReview(rating);
  };
  const isSelected = { border: "1px solid #3232b9" };
  return (
    <div className="ResultItem__rating">
      <p className="text-center text-black mb-0">
        ¿Piensas que el resultado del análisis es fiable?
      </p>
      {loading ? (
        <Spin />
      ) : (
        <>
          <Button
            style={review === like ? isSelected : {}}
            onClick={(e) => onHandleRating(e, like)}
          >
            Sí
          </Button>

          <Button
            style={review === unknown ? isSelected : {}}
            onClick={(e) => onHandleRating(e, unknown)}
          >
            No lo sé
          </Button>
          <Button
            style={review === dislike ? isSelected : {}}
            onClick={(e) => onHandleRating(e, dislike)}
          >
            No
          </Button>
        </>
      )}
    </div>
  );
};

const TMyReportDetailSummary = (props: MyReportProps) => {
  return (
    <div className={`TMyReportDetailSummary ${props.className}`}>
      <TMyReportTitleItem title="Resultados" />
      {props.status === STATUS_TAKS_NUMBER.queue ||
      props.status === STATUS_TAKS_NUMBER.running ? (
        <Skeleton active className="p-2" />
      ) : (
        <div className="TMyReportDetailSummary__body">
          <div className="d-flex flex-column">
            <h3 style={{ fontSize: "1.4rem" }} className="text-black mb-0 mt-1">
              Resultado unificado*
            </h3>
            <p className="my-1">
              El resultado unificado representa la conclusión final obtenida al
              integrar y combinar los resultados proporcionados por cada uno de
              los motores. 
            </p>
            <small className="my-1">
              *El resultado unificado actualmente se encuentra en una primera
              versión y no se puede garantizar la precisión.
            </small>
            <ul className="TMyReportDetailSummary__legend">
              {Array.from(RESULT_SCORE_MAP.values()).map((score) => (
                <li key={score.id} style={{ color: score.color }}>
                  {score.range}
                  <strong className="font-bold"> {score.name} </strong>
                </li>
              ))}
            </ul>
          </div>
          <div className="TMyReportDetailSummary__body-rating">
            <div
              className="d-flex box"
              style={{ background: props.score.background }}
            >
              <ReactSVG
                src={pathServer.PATH_ICONS + "result/" + props.score?.icon}
                className="icon-medium"
              />
              <p style={{ color: props.score.color }} className="pl-0">
                Contenido probablemente{" "}
                <strong className="uppercase font-bold">
                  {" "}
                  {props.score.name}
                </strong>
              </p>
            </div>
            <TRating
              review={props.rating}
              onHandleReview={props.onHandleReview}
            />
          </div>
        </div>
      )}
    </div>
  );
};

TMyReportDetailSummary.displayName = "TMyReportDetailSummary";

TMyReportDetailSummary.defaultProps = {
  className: "",
};

export default TMyReportDetailSummary;
