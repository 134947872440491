import { injectable } from "inversify";
import { ImagePort } from "../../domain/port/inbound/ImagePort";
import { ImageResponse } from "../../domain/entities/Photo";
import {
  API_STATUS,
  TENANT_ID_GENERAL,
} from "../../presentation/utilities/Constant";

const publickURL = `${process.env.REACT_APP_BASE_URL}/api/pub`;

var socket;

@injectable()
export class ImageAdapter implements ImagePort {
  //TODO: refactor all the v2
  /**
   *
   * @param request
   * @returns
   */
  async analyzeCOPImage(request: any): Promise<any> {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    };

    try {
      var formData = new FormData();
      formData.append("content_type", request.deviceSelected);
      formData.append("file", request.file);
      formData.append("tags", request.tags);
      //@ts-ignore
      formData.append("algorithm_list", request.algorithm_list);

      //Send post service
      const response = await fetch(
        publickURL + "/verifai/analysis/file/" + TENANT_ID_GENERAL,
        {
          method: "POST",
          body: formData,
          headers: headers,
        }
      );

      const data = await response.json();
      if (
        response.status === API_STATUS.success ||
        response.status === API_STATUS.accepted
      ) {
        return { ...data, status: API_STATUS.success };
      } else {
        return { ...data, status: API_STATUS.failed, error: data.detail };
      }
    } catch (error) {
      throw new Error("Failed to upload COP file");
    }
  }
}
