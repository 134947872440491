import React, { useState } from "react";

import { Button } from "antd";

import TMyReportPrint from "../MyReportPrint";
import "./style.scss";
export const TELEFONICA_MAIL = "verifai@tu.com";

interface MyReportProps {
  className?: string;
  motors?: [];
  onDownloadReport: (value: string[]) => void;
}

const TMyReportDetailHeader = (props: MyReportProps) => {
  const [isModalPrintOpen, setIsModalPrintOpen] = useState(false);

  const onMailTo = () => {
    window.location.href = "mailto:" + TELEFONICA_MAIL;
  };

  return (
    <div className={`MyReportDetailHeader    ${props.className}`}>
      <Button type="primary" onClick={() => setIsModalPrintOpen(true)}>
        Descargar PDF
      </Button>
      <Button
        type="primary"
        onClick={onMailTo}
        style={{ background: "#2B3447" }}
      >
        Contactar
      </Button>
      <TMyReportPrint
        open={isModalPrintOpen}
        setIsModalOpen={setIsModalPrintOpen}
        motors={props.motors}
        onDownloadReport={props.onDownloadReport}
      />
    </div>
  );
};

TMyReportDetailHeader.displayName = "TMyReportDetailHeader";

TMyReportDetailHeader.defaultProps = {
  className: "",
};

export default TMyReportDetailHeader;
