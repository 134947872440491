import React, { useCallback } from "react";

import { Tag, Collapse, Skeleton, Image, Spin } from "antd";

import TResultItem from "../ResultItem";
import TMyReportTitleItem from "../MyReportTitleItem";
import TMyReportDetailSummary from "../MyReportDetailSummary";
import "./style.scss";
import TCollapseInfo from "../../../CollapseInfo";
import {
  toChartAI,
  unixToDate,
  fileSizeStr,
} from "../../../../../infraestructure/utils/helpers";
import { useSelector } from "react-redux";
import {
  averageAIScore,
  getResultByScore,
  isTaskLoading,
  pathServer,
  RESULT_SCORE,
} from "../../../../utilities/Function";
import { ReactSVG } from "react-svg";

interface MyReportProps {
  className?: string;
  id?: string;
  data?: any;
  task?: any;
  resource?: any;
  onHandleReview?: (review: number) => void;
  onHandleFrame: (reference: number, subTaskId: number) => void;
}

interface TMyReportHeaderSummaryProps {
  loading: boolean;
  resource: string;
  task: any;
  numEngines: number;
}
const IMAGE_PLACEHOLDER_SUMMARY = "/images/placeholder/summary_image.png";
const VIDEO_PLACEHOLDER_SUMMARY = "/images/placeholder/summary_video.png";
const AUDIO_PLACEHOLDER_SUMMARY = "/images/placeholder/summary_audio.png";

const TMyReportHeaderSummary = React.memo(
  (props: TMyReportHeaderSummaryProps) => {
    const { filename, content_type, filesize, mimetype, token, id } =
      props.task;
    return (
      <div
        className={
          "d-flex mt-1 MyReportDetailBody__body pb-1 " +
          (!props.resource ? " placeholder " : "")
        }
      >
        {content_type === "image" ? (
          <Image
            alt={filename}
            src={props.resource}
            className={"MyReportDetailBody__body__img-preview"}
            width={227}
            fallback={IMAGE_PLACEHOLDER_SUMMARY}
          />
        ) : content_type === "video" ? (
          !props.resource ? (
            <Image
              className={"MyReportDetailBody__body__img-preview"}
              width={227}
              fallback={VIDEO_PLACEHOLDER_SUMMARY}
            />
          ) : (
            <video
              controls
              className="MyReportDetailBody__body__video-preview"
              autoPlay={false}
            >
              <source src={props.resource} type="video/mp4" />
            </video>
          )
        ) : (
          <Image
            alt={"audio"}
            src={pathServer.PATH_IMG + "ic_audio.png"}
            className="MyReportDetailBody__body__img-preview"
            fallback={AUDIO_PLACEHOLDER_SUMMARY}
          />
        )}
        <div className="m-auto">
          <div className="d-flex">
            <p>Nombre del archivo: </p>{" "}
            <strong className="font-light">{filename}</strong>
          </div>
          <div className="d-flex">
            <p>ID: </p> <strong className="font-light">{id}</strong>
          </div>
          <div className="d-flex">
            <p> Tamaño :</p>{" "}
            <strong className="font-light">{fileSizeStr(filesize)}</strong>
          </div>
          <div className="d-flex">
            <p> Tipo de archivo :</p>{" "}
            <strong className="font-light">{content_type}</strong>
          </div>
          <div className="d-flex">
            <p> Formato : </p>{" "}
            <strong className="font-light">{mimetype}</strong>
          </div>
          <div className="d-flex">
            <p> Motores utilizados:</p>{" "}
            <strong className="font-light">{props.numEngines}</strong>
          </div>
          <div className="d-flex">
            <p> Tokens utilizados:</p>{" "}
            <strong className="font-light">{token}</strong>
          </div>
        </div>
      </div>
    );
  }
);

const TMyReportDetailBody = (props: MyReportProps) => {
  const { task } = props;
  const { loading } = useSelector((state: any) => state.page);
  const getAverageAI = averageAIScore(task?.subtasks);
  const averageData = getAverageAI
    ? getResultByScore(getAverageAI)
    : RESULT_SCORE.default;
  const subTaks =
    task &&
    task?.subtasks &&
    task?.subtasks
      ?.filter((d) => d.detailed_result.meta_engine !== true)
      .sort((a, b) => a.updated_at > b.updated_at);

  const items = subTaks.map((d, index) => {
    return {
      key: d.id,
      label: (
        <TResultItem
          name={d.algorithm_description}
          percentage={d.ai_score}
          content_type={task.content_type}
          status={d.status}
          message={d.message}
          review={d.review}
          description={d.algorithm_description_long}
          id={d.id}
        />
      ),
      children: (
        <>
          {isTaskLoading(d.status) ? (
            <Skeleton />
          ) : (
            <TCollapseInfo
              index={index}
              loading={loading}
              device={task.content_type}
              duration={Math.floor(d?.detailed_result?.timing?.duration)}
              status={d.status}
              detail={d.detailed_result}
              resource={props.resource}
              thumbnail_uri={d.thumbnail_uri}
              thumbnail_width={d?.thumbnail_width || 227}
              thumbnail_height={d?.thumbnail_height || 256}
              algorithm={d.algorithm}
              onHandleFrame={(reference) =>
                props.onHandleFrame(reference, d.id)
              }
            />
          )}
        </>
      ),
    };
  });

  return (
    <div
      className={`MyReportDetailBody mt-1 mb-1 pt- ${props.className}`}
      id={props.id}
    >
      <div className="border-gray pb-1">
        <div className="MyReportDetailBody__header">
          <div className="d-flex">
            <h1 className="font-medium-2">Resultado del análisis: </h1>
            <p
              style={{ color: averageData?.color, fontSize: "1.1rem" }}
              className="d-flex ml-1 font-medium-2"
            >
              {" "}
              {averageData?.icon && (
                <ReactSVG
                  src={pathServer.PATH_ICONS + "result/" + averageData?.icon}
                  className="icon-small"
                  style={{ marginRight: "5px" }}
                />
              )}
              {loading ? <Spin /> : averageData?.name}
            </p>
          </div>
          <div className="d-flex">
            <Tag className="text-left">{task?.tags}</Tag>
            <div className="ml-auto">
              <p className="font-medium-2"> {unixToDate(task?.created_at)}</p>
            </div>
          </div>
        </div>
      </div>
      <TMyReportHeaderSummary
        loading={loading}
        resource={props.resource}
        task={task}
        numEngines={subTaks?.length}
      />
      <TMyReportDetailSummary
        status={task.status}
        {...toChartAI(task?.subtasks)}
        score={{
          name: averageData?.name,
          color: averageData?.color,
          background: averageData?.background,
          icon: averageData?.icon,
        }}
        className="mt-0"
        onHandleReview={props.onHandleReview}
        rating={task.ratingGlobal || task.subtasks[0]?.review}
      />
      <div>
        <TMyReportTitleItem title="Resultados individuales" />
        <Collapse items={items} defaultActiveKey={["0"]} />
      </div>
    </div>
  );
};

TMyReportDetailBody.displayName = "TMyReportDetailBody";

TMyReportDetailBody.defaultProps = {
  className: "",
};

export default TMyReportDetailBody;
