import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Input, InputRef, Tag } from "antd";

interface TaskTagProps {
  className?: string;
  defaultValue?: string;
  taskId?: string;
  onChange?: (taskID: string, value: string) => void;
}

const TaskTag = ({ onChange, defaultValue, taskId }: TaskTagProps) => {
  const [editable, setEditable] = useState(false);
  const [editted, setEditted] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [timer, setTimer] = useState(null);
  const [progress, setProgress] = useState(0);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    return () => {
      if (timer == null) {
        return;
      }
      clearTimeout(timer);
    };
  }, [timer]);

  const tagStyle = {
    width: "80px",
    height: "24px",
    background: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: "0 8px 0 16px",
  };

  const inputStyle = {
    width: "100px",
    height: "24px",
    background: "white",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: "12px",
    margin: "0 8px 0 16px",
  };

  const enableEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setEditable(!editable);
    if (editable) {
      startTime();
    }
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const startTime = () => {
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1);
    }, 1000);
    setTimer(interval);
  };

  useEffect(() => {
    if (progress >= 3) {
      setEditted(true);
      setTimeout(() => {
        setValue(inputRef.current.input.value);
        onChange(taskId, value);
        setEditable(false);
        setProgress(0);
        clearTimeout(timer);
      }, 200);
    }
  }, [progress, timer]);

  const handleInputChange = (event) => {
    setValue(event.target.value);
    clearTimeout(timer);
    setProgress(0);
    setTimer(setTimeout(startTime, 200));
  };

  const inputProps = {
    style: { ...inputStyle },
    defaultValue: value,
    ref: inputRef,
    onChange: handleInputChange,
  };

  const tagInput = (
    <div className={editted ? "input-animated" : ""}>
      <Input
        {...inputProps}
        className="text-center"
        placeholder="Type new tag"
        onClick={stopPropagation}
        onFocus={stopPropagation}
        size="small"
      />
    </div>
  );

  const tagComponent = (
    <Tag
      onClick={enableEdit}
      onFocus={stopPropagation}
      style={tagStyle}
      className="text-center ml-1"
    >
      {value}
    </Tag>
  );

  return <>{editable ? tagInput : tagComponent}</>;
};

export default TaskTag;
