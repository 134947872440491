import React from "react";

import TMyReportDetailHeader from "./components/MyReportDetailHeader";
import TMyReportDetailBody from "./components/MyReportDetailBody";

import "./style.scss";

// Define your props here
interface MyReportProps {
  className?: string;
  detailJob?: any;
  resource?: any;
  onHandleReview?: (review: number) => void;
  onHandleFrame: (reference: number, subTaskId: number) => void;
  onDownloadReport: (value: string[]) => void;
}

const TMyReportDetail = (props: MyReportProps) => {
  return (
    <div className={`MyReportDetail mt-2 mb-1 pt-1 pb-1 ${props.className}`}>
      <TMyReportDetailHeader
        motors={props.detailJob.subtasks}
        onDownloadReport={props.onDownloadReport}
      />
      {props.detailJob && (
        <TMyReportDetailBody
          id="MyReportDetailBody"
          task={props.detailJob}
          resource={props.resource}
          onHandleReview={props.onHandleReview}
          onHandleFrame={props.onHandleFrame}
        />
      )}
    </div>
  );
};

TMyReportDetail.displayName = "TMyReportDetail";

TMyReportDetail.defaultProps = {
  className: "",
};

export default TMyReportDetail;
